/* THIS FILE CONVERTS FUNCTION-BASED CONTEXTS SO THEY CAN BE USED IN CLASS-BASED FILES */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useHighlightedPoles } from './HighlightedPolesContext';

const withHighlightedPoles = (Component) => {
    return function WrappedComponent(props) {
        const highlightedPolesContext = useHighlightedPoles();
        const navigate = useNavigate();
        return <Component {...props} highlightedPolesContext={highlightedPolesContext} navigate={navigate} />;
    };
};

export default withHighlightedPoles;
