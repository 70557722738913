import React, { useState, useEffect } from "react";
import {
    Chart,
    ChartArea,
    ChartTitle,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisTitle,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartSeriesLabels,
    ChartSeriesItemTooltip,
    ChartTooltip
} from "@progress/kendo-react-charts";
import {
    ExpansionPanel,
    ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { drawing, geometry } from '@progress/kendo-drawing';
import { useProject } from '../../contexts/ProjectContext';
import loadinggif from "../../assets/loading.gif";
import CSMapPage from "./rs_mapcontainer";
import noaccess from "../../assets/images/noaccess.png";
import noaccesstext from "../../assets/images/noaccesscontent.png";

function ReliabilityDashboard() {
    //arrays of data for graphs
    const [totalRejectCount, setTotalRejectCount] = useState([]);
    const [totalRejectPercent, setTotalRejectPercent] = useState([]);
    const [predictiveChartData, setPredictiveChartData] = useState([]);
    const [predictiveCategories, setPredictiveCategories] = useState([]);
    const [inspectionType, setInspectionType] = useState([]);
    //const [loading, setLoading] = React.useState(false);
    const [daysOfMonth, setDaysOfMonth] = useState([]);
    const [tempAreaChartData, setTempAreaChartData] = useState([]);

    //arrays of strings for graph labels
    const [totalRejectCat, setTotalRejectCat] = useState([]);
    const [percentRejectCat, setPercentRejectCat] = useState([]);

    //Project context
    const { selectedProject, GCP_Project, Dataset_ID } = useProject();

    //util classes
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState({ skip: 0, take: 5 });
    const [expanded, setExpanded] = useState(1);
    const [nodata, setNodata] = useState(false);

    useEffect(() => {
        if (selectedProject && GCP_Project && Dataset_ID) {
            getChartData();
        }
    }, [selectedProject, GCP_Project, Dataset_ID]);

    function calculatePercent(count, total) {
        return ((count / total) * 100).toFixed(2)
    }

    function pageChange(event) {
        const targetEvent = event.targetEvent;
        const take = targetEvent.value === "All" ? inspectionType.length : event.page.take;
        setPage({
            ...event.page,
            take,
        });
    };

    function generateRandomHexValue() {
        let ret = "";
        for (let i = 0; i < 6; ++i) {
            const rng = Math.floor(Math.random() * 16);
            ret += rng.toString();
        }
        return ret;
    }

    function getChartData() {
        setLoading(true);
        try {
            fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/Secrets/GetNimblyWebAPIKey', {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*" },
                //mode: 'no-cors',
            })
                .then((res) => {
                    return res.text();;
                })
                .then((data) => {
                    //console.log(data);
                    fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/poles/GetReliabilityChart', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json', 'ApiKey': data, },
                        body: JSON.stringify({
                            rawQuery: "",
                            Project: selectedProject,
                            GCP_Project: GCP_Project, //CHANGE FOR PROD
                            Dataset_ID: Dataset_ID
                        })
                    })
                        .then((res) => {
                            if (!res.ok) {
                                setNodata(true);
                                setLoading(false);
                                throw new Error('This project has no data to display');
                            }
                            setNodata(false);
                                return res.json();;
                        })
                        .then((data) => {
                            

                           
                            let tempCount = [];
                            let tempPercent = [];
                            let tempCountCat = [];
                            let tempPercentCat = [];
                            let tempInspection = [];

                            Object.keys(data.CategoryToCount).forEach(key => {
                                tempCountCat.push(key);
                                tempCount.push(data.CategoryToCount[key].Item1);
                                tempPercentCat.push(key);
                                tempPercent.push(data.CategoryToCount[key].Item2);
                            });

                            let l = 0;
                            Object.keys(data.CategoryToInspection).forEach(key => {
                                l += data.CategoryToInspection[key];
                            });

                            const colors = ['#FF5733', '#FFD700', '#1E90FF', '#32CD32', '#FFA500', '#800080', '#FF6347', '#FFFF00', '#0000CD', '#00FF00'];
                            let i = 0;
                            Object.keys(data.CategoryToInspection).forEach(key => {
                                tempInspection.push({
                                    category: key,
                                    count: data.CategoryToInspection[key],
                                    percent: calculatePercent(data.CategoryToInspection[key], l),
                                    color: i < colors.length ? colors[i++] : generateRandomHexValue()
                                });
                            });

                            setTotalRejectCount(tempCount);
                            setTotalRejectPercent(tempPercent);
                            setInspectionType(tempInspection);
                            setTotalRejectCat(tempCountCat);
                            setPercentRejectCat(tempPercentCat);
                            
                            fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/poles/GetReliabilityPredictiveAnalyticsChart', {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json', 'ApiKey': data },
                                body: JSON.stringify({
                                    rawQuery: "",
                                    Project: selectedProject,
                                    GCP_Project: GCP_Project,
                                    Dataset_ID: Dataset_ID,
                                }),
                            })
                                .then((res) => res.json())
                                .then((predictiveData) => {
                                    const chartData = predictiveData.map((item) => ({
                                        category: item.RejectionStatus || "Unknown",
                                        EstimatedCounts: item.EstimatedCounts,
                                        TotalCountYTD: item.TotalCountYTD,
                                    }));
                                    const categories = predictiveData.map((item) => item.RejectionStatus || "Unknown");
                                    setPredictiveChartData(chartData);
                                    setPredictiveCategories(categories);
                                    setLoading(false);
                                });
                        });
                });
        } catch (ex) {
            console.log("<------------------------------>");
            console.log("Error fetching chart data for rs");
            console.error(ex);
        } 
    }

    //graph component for total reject
    //is count is a bool true for the count graph, false for the percent
    function TotalRejectComponent({ isCount }) {
       
        function tooltipRender(e) {
            return isCount ? `${e.point.value}` : `${e.point.value}%`;
        }

        return (
            <Chart>
                <ChartArea height={300} />
                <ChartTooltip render={tooltipRender} />
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem categories={isCount ? totalRejectCat : percentRejectCat}>
                        <ChartCategoryAxisTitle
                            text="Reject Type"/>
                    </ChartCategoryAxisItem>
                </ChartCategoryAxis>
                <ChartValueAxis>
                    <ChartValueAxisItem
                        labels={{
                            visible: true,
                            content: e => isCount ? `${e.value}` : `${e.value}%`
                        }}
                    />
                </ChartValueAxis>
                <ChartSeries>
                    <ChartSeriesItem
                        type="column"
                        data={isCount ? totalRejectCount : totalRejectPercent}
                        color="#4285f4"
                        tooltip={{ visible: true }}
                    />
                </ChartSeries>
            </Chart>
        );
    }

    //graph component for predictive analytics
    function PredictiveAnalyticsChart() {
        function tooltipRender(e) {
            const { category, series, value } = e.point;
            return `${category} - ${series.name}: ${value}`;
        }

        return (
            <Chart>
                <ChartArea height={300} />
                <ChartLegend position="top" />
                <ChartTooltip render={tooltipRender} />
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem categories={predictiveCategories}>
                        <ChartCategoryAxisTitle text="Reject Status" />
                    </ChartCategoryAxisItem>
                </ChartCategoryAxis>
                <ChartValueAxis>
                    <ChartValueAxisItem
                        labels={{
                            visible: true,
                            content: (e) => `${e.value}`,
                        }}
                    />
                </ChartValueAxis>
                <ChartSeries>
                    <ChartSeriesItem
                        type="column"
                        name="Total Count YTD"
                        data={predictiveChartData.map((item) => item.TotalCountYTD)}
                        spacing={0.1}
                        color="#4285F4"
                    />
                    <ChartSeriesItem
                        type="column"
                        name="Estimated Counts"
                        data={predictiveChartData.map((item) => item.EstimatedCounts)}
                        spacing={0.1}
                        color="#051A38"
                    />
                </ChartSeries>
            </Chart>
        );
    }

    //graph component for inspection count
    function InspectionCountComponent() {

        function tooltipRender(e) {
            const t = e.dataItem != undefined ? e.dataItem : e.point.dataItem;
            return ` ${t.category} - ${t.count} - ${t.percent}%`;
        }

        function InspectionTypeName(props) {
            const { dataItem } = props;
            console.log(dataItem);
            return (
                <td>
                    <div className="inspection-cc">
                        <div style={{ width: '16px', height: '16px', backgroundColor: dataItem.color, marginRight: "4px" }}></div>
                        <div>{dataItem.category}</div>
                    </div>
                </td>
            );
        }

        return (
            <div className="pulse-inspection-wrapper">
                <Chart className="pulse-chart">
                    <ChartArea height={300}/>
                    <ChartLegend visible={false} />
                    <ChartTooltip render={tooltipRender} />
                    <ChartSeries>
                        <ChartSeriesItem
                            type="donut"
                            data={inspectionType}
                            field="count"
                            categoryField="category"
                            tooltip={{ visible: true }}
                        />
                    </ChartSeries>
                </Chart>
                <Grid
                    data={inspectionType.slice(page.skip, page.take + page.skip)}
                    skip={page.skip}
                    take={page.take}
                    total={inspectionType.length}
                    onPageChange={pageChange}
                    pageable={true}
                    style={{ width: '50%', height: 325, }}
                    scrollable={false}
                >
                    <Column title="Inspection Type" field="category" cells={{ data: InspectionTypeName }}/>
                    <Column title="Count" field="count" width="96px"/>
                    <Column title="Percent" field="percent" width="96px"/>
                </Grid>
            </div>
        );
    }

    //Component payload
    function Payload() {
        return (
            nodata ? <><img className='pulse-no-access-image' src={noaccess} />
                <h3 className='pulse-no-access-image-text'>This project does not have any data yet.</h3>
            </> : <div className="ken-nimbly-ov-page-content-wrapper">
                        <div className="pulse-dashboard-chart-title">
                            <p className="pulse-chart-main-title">Chart View:</p>
                        </div>

                    <div className="pulse-dashboard-chart-grid">
                        {/* Total Reject Poles (Count) */}
                        <div className="chart-section">
                            <p className="pulse-chart-title">Total Reject Poles (Count):</p>
                            <div className="pulse-chart-container">
                                <TotalRejectComponent isCount={true} />
                            </div>
                        </div>

                        {/* Total Reject Poles (Percentage) */}
                        <div className="chart-section">
                            <p className="pulse-chart-title">Total Reject Poles (Percentage)</p>
                            <div className="pulse-chart-container">
                                <TotalRejectComponent isCount={false} />
                            </div>
                        </div>

                        {/* Inspection Type (Percentages and Quantities/Counts) */}
                        <div className="chart-section">
                            <p className="pulse-chart-title">Inspection Type (Percentages and Quantities/Counts):</p>
                            <div className="pulse-chart-container">
                                <InspectionCountComponent />
                            </div>
                        </div>

                        {/* Predictive Analytics Chart */}
                        <div className="chart-section">
                            <p className="pulse-chart-title">Projected Reject Status</p>
                            <div className="pulse-chart-container">
                                <PredictiveAnalyticsChart />
                            </div>
                        </div>
                    </div>
                </div>

        );
    }

    return loading ? <img className='nimbly2-loader-gif' src={loadinggif} /> : <Payload />;
}

export default ReliabilityDashboard;