import React, { createContext, useContext, useState } from "react";

const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
    const [selectedProject, setSelectedProject] = useState('');
    const [previousProject, setPreviousProject] = useState(null); 
    const [GCP_Project, setGCP_Project] = useState('');
    const [Dataset_ID, setDataset_ID] = useState('');
    const [segment, setSegment] = useState('');

    const handleProjectChange = (newProject) => {
        setPreviousProject(selectedProject); 
        setSelectedProject(newProject); 
    };

    return (
        <ProjectContext.Provider
            value={{
                selectedProject,
                setSelectedProject,
                previousProject,
                handleProjectChange,
                GCP_Project,
                setGCP_Project,
                Dataset_ID,
                setDataset_ID,
                segment,
                setSegment
            }}
        >
            {children}
        </ProjectContext.Provider>
    );
};

export const useProject = () => useContext(ProjectContext);
