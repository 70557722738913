import React, { createContext, useContext, useState, useMemo } from 'react';

const QueryResultsContext = createContext();

export const QueryResultsProvider = ({ children }) => {
    const [queryResults, setQueryResults] = useState([]);

    const isQueryActive = useMemo(() => queryResults.length > 0, [queryResults]);

    return (
        <QueryResultsContext.Provider value={{ queryResults, setQueryResults, isQueryActive }}>
            {children}
        </QueryResultsContext.Provider>
    );
};

export const useQueryResults = () => useContext(QueryResultsContext);
