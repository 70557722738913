
import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import "../styles/login.css"
import {Button as KenButton } from "@progress/kendo-react-buttons";

function LoginButton(props) {
    const { loginWithRedirect } = useAuth0()


    return (
            <KenButton
                onClick={() => loginWithRedirect({
                    appState: {
                       returnTo: '/overview'// here
                    }
                })
                }
                className="nimbly-login-btn"
            >
                Sign In
            </KenButton>
    )
}

export default LoginButton