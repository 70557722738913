import React from "react";
import {ButtonGroup as KenButtonGroup, Button as KenButton } from "@progress/kendo-react-buttons";
import "../styles/nimbly2.css"

const TopNavBar = () => {
  
    return (
    <div className="ken-nimbly-top-nav">
        <p>OVERVIEW</p>
    </div>
    )
}
export default TopNavBar;