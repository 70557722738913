import React from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import RSPoleMap from './rs_polemap'

function RSGoogleMapLoader(props) {
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyB1rmwwP3SKl8a47tX4BYPIIwQZsuWLZRQ",
        libraries: ['places', 'drawing']
    })

    const renderMap = () => {
        return <RSPoleMap poleDataProps={props.poleDataProps} handleMapFullSize={props.handleMapFullSize} fullSizeMap={props.fullSizeMap} />
    }

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>
    }

    return isLoaded ? renderMap() : null
}

export default React.memo(RSGoogleMapLoader)
