/* THIS FILE CONVERTS FUNCTION-BASED CONTEXTS SO THEY CAN BE USED IN CLASS-BASED FILES */
import React from 'react';
import { useTabContext } from './ReportTabContext'; // Adjust the path as needed

const withTabContext = (Component) => {
    return function WrappedComponent(props) {
        const tabContext = useTabContext();
        return <Component {...props} tabContext={tabContext} />;
    };
};

export default withTabContext;
