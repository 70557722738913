import CSDashoboard from "../construction_services/cs_dashboard";
import CSReportPage from "../construction_services/cs_report_page";
import CSUserPage from "../construction_services/cs_user_page";
import ReliabilityReportPage from "../reliability_services/rs_report_page";
import ReliabilityDashboard from "../reliability_services/rs_dashboard";
import MapPage from "./map_page";

import { useProject } from '../../contexts/ProjectContext'; 

export function DashboardWrapper() {
    const { segment } = useProject();
    const Element = segment == "Doublewood" ? <CSDashoboard /> : <ReliabilityDashboard />;
    return Element;
}

//for when the map and dasboard are seperated
export function MapWrapper() {
    const { segment } = useProject();
    const Element = <MapPage />;
    return Element;
}

export function UserWrapper() {
    //Currently there is only one user page across all segments
    //Abstraction exists in case we need to have multiple user pages for whatever reason
    return <CSUserPage />;
}

export function ReportWrapper() {
    const { segment } = useProject();
    const Element = segment == "Doublewood" ? <CSReportPage /> : <ReliabilityReportPage/>;
    return Element;
}