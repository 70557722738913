/* THIS FILE CONVERTS FUNCTION-BASED CONTEXTS SO THEY CAN BE USED IN CLASS-BASED FILES */
import React from 'react';
import { useProject } from './ProjectContext';

const withProject = (Component) => {
    return function WrappedComponent(props) {
        const projectContext = useProject();
        return <Component {...props} projectContext={projectContext} />;
    };
};

export default withProject;
