import React from "react";
import "../../styles/nimbly2.css";
import MapImage from "../../assets/nimbly_map.png";
import ExpandedPole from "../../global_components/pole_popup";
import { DateRangePicker as KenDateRangePicker } from "@progress/kendo-react-dateinputs";
import RSGoogleMapLoader from "./rs_maploader";

const RSMapPage = () => {
    const poleData = {
        number: "12516247196",
        status: "Not Audited",
        location: "28.72667500, -96.36619000",
        condition: "PR1 Restorable",
        adders: "Cobra Rod Install"
    };

    return (
        <div className="map-container">
            <RSGoogleMapLoader />
        </div>
    );
};

export default RSMapPage;
