import React, { useState, useEffect } from "react";
import { DropDownList as KenDropDownList } from "@progress/kendo-react-dropdowns";
import { DropDownButton as KenDropDownButton } from "@progress/kendo-react-buttons"; 
import "../styles/nimbly2.css";
import "../kendo-default-official-project-file-for-nimble/dist/css/kendo-default-official-project-file-for-nimble.css";
import ProfilePicture from '../icons/prof_pic.png';
import DropDownIcon from '../icons/dropdown_icon.png';
import LogoutButton from './logoutbutton';
import { useProject } from '../contexts/ProjectContext'; 
import { useUser } from '../contexts/UserContext';
import { useHighlightedPoles } from '../contexts/HighlightedPolesContext';
import { useQueryResults } from '../contexts/QueryResultsContext'; 
import { useTabContext } from '../contexts/ReportTabContext';

const profileDropdownItems = [
    <LogoutButton />
];

const NimblyHeader = (props) => {
    const { selectedProject, setSelectedProject, setGCP_Project, setDataset_ID, setSegment, previousProject, handleProjectChange } = useProject();
    const { selectedTab, setSelectedTab } = useTabContext(); // THIS IS FOR QUERY WIZARD/REPORT PAGE
    const { user } = useUser();
    const { setHighlightedPoles } = useHighlightedPoles(); 
    const { setQueryResults } = useQueryResults(); 
    const [projectDropdownItems, setProjectDropdownItems] = useState([]);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/Poles/GetProjectAliases');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                //console.log("Fetched projects:", data); 

                // Extract the user's assigned project Dataset_IDs
                const userProjects = user && user.Project ? user.Project.split(',') : [];

                // Filter the projects to include only those assigned to the user
                const filteredProjects = data.filter(project => userProjects.includes(project.Dataset_ID));

                setProjectDropdownItems(filteredProjects);
                //console.log("h", filteredProjects)
                // If there are any filtered projects, set the first one as the selected project
                if (filteredProjects.length > 0) {
                    setSelectedProject(filteredProjects[0].Alias);
                    setGCP_Project(filteredProjects[0].GCP_Project);
                    setDataset_ID(filteredProjects[0].Dataset_ID);
                    setSegment(filteredProjects[0].Segment);
                }
            } catch (error) {
                console.error("Error fetching project aliases", error);
            }
        };

        fetchProjects();
    }, [setSelectedProject, setGCP_Project, setDataset_ID, user]);

    const changeProject = (e) => {
        const selectedAlias = e.target.value.Alias;
        const selectedProject = projectDropdownItems.find(item => item.Alias === selectedAlias);
        if (selectedProject !== previousProject) {
            setHighlightedPoles([]);
            setQueryResults([]);
            setSelectedTab(0);
            console.log("HEADER CLEARING POLES/QUERY RESULTS")
        }
        if (selectedProject) {
            handleProjectChange(selectedProject.Alias);
            setGCP_Project(selectedProject.GCP_Project);
            setDataset_ID(selectedProject.Dataset_ID);
            setSegment(selectedProject.Segment);
        } else {
            console.error("Selected project is undefined. Alias:", selectedAlias);
        }
    };


    return (
        <div className="ken-nimbly-header">
            <div className="ken-nimbly-slct-proj-wrapper">
                <p className="header-text">Select Project:</p>
                <KenDropDownList
                    className="ken-nimbly-header-proj-drpdwn"
                    data={projectDropdownItems}
                    textField="Alias"
                    dataItemKey="Alias"
                    onChange={changeProject}
                    value={projectDropdownItems.find(item => item.Alias === selectedProject) || null}
                />
            </div>
            <div className="ken-nimbly-slct-proj-wrapper" style={{ position: "absolute", width: "100%", display: "flex", justifyContent: "center" }}>
                <p style={{ color: "orange" }} className="header-text">v1.0.0.2</p>
            </div>
            <div className="ken-nimbly-profile-section">
                <img className="user-pic" src={props.user.picture} alt="missing profilepic" />
                <KenDropDownButton className="ken-nimbly-header-prof-drpdwn"
                    text={props.user.name}
                    items={profileDropdownItems} />
            </div>
        </div>
    );
}

export default NimblyHeader;
