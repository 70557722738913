import React, { Component } from 'react';
import { GoogleMap, LoadScript, MarkerF, Autocomplete, InfoWindow, DrawingManagerF, Polygon, useJsApiLoader, MarkerClusterer } from '@react-google-maps/api';
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import ExpandedPole from "../../global_components/cs_pole_popup";
import ExpandedPoleLasso from "../../global_components/pole_popup_lasso";
import drawIcon from "../../assets/Vector1.png";
import offdrawIcon from "../../assets/Vector2.png";
import loadinggif from "../../assets/loading.gif";
import loadingtext from "../../assets/loadingmaps.png";
import withProject from "../../contexts/class_context_convert.js";
import withTabContext from "../../contexts/report_tab_context_convert.js";
import withHighlightedPoles from "../../contexts/highlighted_poles_class_context_convert.js";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import selectedBlackIcon from "../../assets/SymbologyIcons/selectedBlack.png";
import darkRedIcon from "../../assets/SymbologyIcons/darkRedIcon.png";
import noaccess from "../../assets/images/noaccess.png";
import limeGreenIcon from "../../assets/SymbologyIcons/limeGreenIcon.png";
import auditedPin from "../../assets/auditedpin.png";
import notauditedPin from "../../assets/notauditedpin.png";
import selectedRedIcon from "../../assets/SymbologyIcons/selectedRed.png";
import selectedGreenIcon from "../../assets/SymbologyIcons/selectedGreen.png";
import unselectedRedIcon from "../../assets/SymbologyIcons/unselectedRed.png";
import unselectedGreenIcon from "../../assets/SymbologyIcons/unselectedGreen.png";
import { DateRangePicker as KenDateRangePicker } from "@progress/kendo-react-dateinputs";
import moment from 'moment'
import { bordersShowHideIcon } from '@progress/kendo-svg-icons';
import {
    Button,
    ButtonGroup,
    DropDownButton,
    DropDownButtonItem,
    FloatingActionButton,
    SplitButton,
    SplitButtonItem,
    Toolbar,
    ToolbarSeparator,
    ChipList,
} from "@progress/kendo-react-buttons";

const getMapOptions = () => {
    if (window.google && window.google.maps) {
        return {
            fullscreenControl: false,
            styles: [
                {
                    elementType: "labels",
                    featureType: "poi",
                    stylers: [{ visibility: "off" }],
                },
            ],
            mapTypeControl: true,
            mapTypeControlOptions: {
                position: window.google.maps.ControlPosition.TOP_RIGHT,
            },
        };
    }
    return {}; // Fallback if Google Maps is not loaded
};

const containerStyle = {
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    borderColor: '#BFBFBF',
}

const containerStyleFullSize = {
    width: '100%',
    height: '81vh',
    borderRadius: '10px',
    borderColor: '#BFBFBF'
}

class RSPoleMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mapData: [],
            currentSelectedPole: null,
            currentCenter: {
                lat: 39.87597539,
                lng: -86.06844941
            },
            showAudited: true,
            showNotAudited: true,
            filteredMapData: [],
            showInfoWindow: true,
            checkedMaplegend: "No Status",
            originalData: null,
            showDrawer: true,
            currentPin: "default",
            loading: true,
            map: {},
            poleadderList: [],
            poleconditionList: [],
            poleauditstatusList: [],
            rawQuery: null,
            created_user: [],
            Reject_Status: [],
            created_user_checkbox: true,
            Reject_Status_checkbox: true,
            checkboxvalue: false,
            nodata: false,
            dateStart: null,
            dateEnd: null,
            showDate: {},
            counts: 0,
            drawingRec: null,
            lassoWindow: false,
            lassoData: null,
            drawOption: null,
            datevalue: null,
            audit_checkbox: true,
            zoomLevel: 16
        };
        this.autocomplete = null
        this.onLoad = this.onLoad.bind(this)
        this.onPlaceChanged = this.onPlaceChanged.bind(this)
        this.handleMarkerOnClick = this.handleMarkerOnClick.bind(this)
        this.handleMapOnClick = this.handleMapOnClick.bind(this)
        this.handlePoleonChange = this.handlePoleonChange.bind(this)
    }

    componentDidMount() {
        if (this.props.projectContext.selectedProject !== "") {
            this.populateMapData("");
        }
    }

    componentDidUpdate(prevProps) {
        const { highlightedPoles } = this.props.highlightedPolesContext;

        // Handle highlighted poles context changes
        if (prevProps.highlightedPolesContext.highlightedPoles !== highlightedPoles) {
            this.updateFilteredMapData();
        }

        // Handle project changes
        if (this.props.projectContext.selectedProject !== prevProps.projectContext.selectedProject) {
            this.setState({ nodata: false, loading: true }, () => {
                this.populateMapData(""); 
                this.handleClearMapFilter(); 
            });
        }
    }



    handlePoleonChange(e, type, pinType) {
        var locationWaitingStatusList = ["Pole not Set", "Electric Power", "Heavy Vegetation", "Pole Needs Topping", "No Access"]
        var updatedMapData;
        if (type === this.state.checkedMaplegend) {
            this.setState({
                checkedMaplegend: "No Status",
                currentPin: "default",
                currentSelectedPole: null,
                loading: true
            }, () => {
                let boundInput = this.getBounds();
                // this.populateMapData(boundInput, "No Status");
            })
        }
        else {
            this.setState({
                checkedMaplegend: type,
                currentSelectedPole: null,
                currentPin: pinType,
                loading: true
            }, () => {
                let boundInput = this.getBounds();
                // this.populateMapData(boundInput, type);
            })
        }

    }

    handleMarkerOnClick(marker, e) {
        this.setState({
            currentSelectedPole: null,
        }, () => {
            this.setState({
                currentSelectedPole: marker,
            });
        })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            var searchInput = e.target.value;
            var lookupData = this.state.mapData;
            var test = lookupData.filter(function (data) {
                return lookupData !== null ? data.info.Pole_Number === searchInput : null
            });

            this.setState({
                currentSelectedPole: null,
                zoomLevel: null
            }, () => {
                this.setState({
                    currentSelectedPole: test.length === 0 ? null : test[0],
                    zoomLevel: 16
                });
            })
        }
    }

    handleMapOnClick() {
        this.setState({
            currentSelectedPole: null,
        })
    }

    handleDraw() {
        this.setState({
            drawOption: "rectangle",
        })
    }

    handleOffDraw() {
        this.state.drawingRec.setMap(null);
        this.setState({
            drawOption: "maker",
            lassoWindow: false
        })
    }

    onLoad(autocomplete) {
        this.autocomplete = autocomplete
    }

    onPlaceChanged() {
        //251-B110
        if (this.autocomplete !== null) {
            var searchCallback = this.autocomplete.getPlace();
            var searchInput = searchCallback.name.toString();
            if (searchCallback.geometry ?.viewport) {
                this.setState({
                    currentCenter: searchCallback.geometry.location,
                    currentSelectedPole: null
                })
            }

            //var lookupData = this.state.mapData;
            //var test = lookupData.filter(function (data) {
            //    return lookupData !== null ? data.info.Pole_Number === searchInput : null
            //});
            //this.setState({
            //    currentSelectedPole: null,
            //}, () => {
            //    this.setState({
            //        currentSelectedPole: test.length === 0 ? null : test[0],
            //    });
            //})
        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }

    showDrawer = () => {
        this.setState({
            showDrawer: !this.state.showDrawer
        })
    }

    getBounds = () => {
        let bounds = this.state.map.getBounds();
        let center = this.state.map.getCenter()

        let lat = center.lat();
        let long = center.lng();

        let ne = bounds.getNorthEast(); // LatLng of the north-east corner
        let sw = bounds.getSouthWest(); // LatLng of the south-west corner

        let bbox = {
            north: ne.lat(),
            east: ne.lng(),
            south: sw.lat(),
            west: sw.lng(),
            lat: lat,
            long: long
        };

        return bbox;
    }
    boundsCallBack = () => {
        this.setState({
            loading: true
        })
        setTimeout(() => {
            let boundInput = this.getBounds();
            // this.populateMapData(boundInput, this.state.checkedMaplegend);
        }, 500)
    }

    boundsZoomCallBack = () => {
        setTimeout(() => {
            this.setState({
                loading: true
            })
            let boundInput = this.getBounds();
            // this.populateMapData(boundInput, this.state.checkedMaplegend);
        }, 1000)
    }

    updateFilteredMapData = () => {
        const { highlightedPoles } = this.props.highlightedPolesContext;
        const { mapData, showAudited, showNotAudited } = this.state;

        const filtered = mapData.filter((item) => {
            const isHighlighted = highlightedPoles.includes(item.info ?.Globslid);

            if (highlightedPoles.length > 0) {
                return isHighlighted;
            }

            if (!showAudited && item.Audit_Date) return false;
            if (!showNotAudited && !item.Audit_Date) return false;

            return true;
        });

        this.setState({ filteredMapData: filtered }, () => {
            if (highlightedPoles.length > 0 && filtered.length > 0) {
                const firstHighlightedPole = filtered.find(pole =>
                    highlightedPoles.includes(pole.info ?.Globslid)
                );
                if (firstHighlightedPole) {
                    this.setState({
                        currentCenter: firstHighlightedPole.coords,
                        zoomLevel: 16
                    });
                }
            }
        });
    };


    handleMapLoad = (map) => {
        this.setState((state) => ({ ...state, map }));
        setTimeout(() => {
            let boundInput = this.getBounds();
            // this.populateMapData(boundInput, this.state.checkedMaplegend);
        }, 500)
    }

    handleChange = (e) => {
        if (e.value.end != null) {
            this.setState({
                dateStart: moment(e.value.start).format('yyyy-MM-DD hh:mm:ss'),
                dateEnd: moment(e.value.end).format('yyyy-MM-DD hh:mm:ss'),
                showDate: e.value,
            }, () => { this.handleFilterUpdate() })
        }
    }

    handleCleanDate = () => {
        this.setState({
            dateStart: null,
            dateEnd: null,
            showDate: {}
        }, () => { this.handleFilterUpdate() })
    }

    handleClearMapFilter = () => {
        const { setHighlightedPoles } = this.props.highlightedPolesContext;
        setHighlightedPoles([]);

        this.setState({
            showAudited: true,
            showNotAudited: true,
            filteredMapData: this.state.mapData,
            currentSelectedPole: null,
        });
    }

    handleGoToReport = () => {
        const { setSelectedTab } = this.props.tabContext;
        setSelectedTab(1);
        this.props.navigate('/report');

        this.setState({ currentSelectedPole: null });
    }

    handleFilterUpdate = () => {
        this.setState({
            loading: true
        })
        var rawQuery = "";
        var tempQuery = "";
        var tempQuery1 = "";
        var tempQuery2 = "";
        var tempQuery3 = "";
        var temparray = [];
        if (this.state.created_user.length > 0) {
            var tempString = this.state.created_user.map((value) => `${"'" + value + "'"}`).join(',');
            //console.log(tempString);
            tempQuery = "created_user In (" + tempString + ")";
        }
        else {
            tempQuery = "";
        }

        if (this.state.Reject_Status.length > 0) {
            var tempString = this.state.Reject_Status.map((value) => `${value}`).join(',');
            //console.log(tempString);
            tempQuery1 = "Reject_Status In (" + tempString + ")";
        }
        else {
            tempQuery1 = "";
        }

        if (this.state.dateEnd != null && this.state.dateStart != null) {
            var tempString = "created_date < '" + this.state.dateEnd + "' and created_date > '" + this.state.dateStart + "'";
            //console.log(tempString);
            tempQuery2 = tempString;
        }
        else {
            tempQuery2 = "";
        }
        if (this.state.audit_childbox_audited) {
            tempQuery3 = 'audit_user is not null'
        }

        if (tempQuery !== "") {
            temparray.push(tempQuery);
        }
        if (tempQuery1 !== "") {
            temparray.push(tempQuery1);
        }
        if (tempQuery2 !== "") {
            temparray.push(tempQuery2);
        }
        if (tempQuery3 !== "") {
            temparray.push(tempQuery3);
        }
        var rawQuery = temparray.map((value) => `${value}`).join(' and ');
        // rawQuery = tempQuery + (tempQuery == "" ||  tempQuery1 == "" ? "" : " And ") + tempQuery1 + tempQuery2 === "" ? "" : " and " + tempQuery2;
        this.populateMapData(rawQuery);
    }
    handleViewButtonOnClick = () => {
        //console.log("1");
    }

    handleCondition = (condition) => {
        if (condition === "1") {
            return "Non Reject";
        }
        else if (condition === "2") {
            return "Non Restorable Reject";
        }
        else if (condition === "3") {
            return "Priority Non Restorable Reject";
        }
        else if (condition === "4") {
            return "Priority Restorable Reject";
        }
        else if (condition === "5") {
            return "Restorable Reject";
        }
    }

    assignPoleSymbologyColor = (pole) => {
        const { hasHighlightedPoles, highlightedPoles } = this.props.highlightedPolesContext;

        const isHighlighted = highlightedPoles.includes(pole.info ?.Globslid);

        if (!hasHighlightedPoles) {
            if (pole) {
                if (pole.Audit_Date) {
                    return selectedGreenIcon;
                } else {
                    return selectedRedIcon;
                }
            }
            return selectedBlackIcon;
        } else {
            if (isHighlighted) {
                if (pole.Audit_Date) {
                    return selectedGreenIcon;
                } else {
                    return selectedRedIcon;
                }
            } 
        }
    };



    render() {
        const { highlightedPoles } = this.props.highlightedPolesContext;

        const filteredMapData = this.state.filteredMapData;

        const legendWithoutFilterApplied = (
            <div className="pulse-map-legend-rs">
                <GridLayout
                    gap={{
                        rows: 2,
                        cols: 2,
                    }}
                >
                    <GridLayoutItem row={1} col={1} className="map-icon-layoutitem" >
                        <img src={selectedGreenIcon} alt="Pole" />
                    </GridLayoutItem>
                    <GridLayoutItem className="pulse-map-legend-grid" row={1} col={2}>
                        <div className="legend-row">
                            Audited
                            <Checkbox
                                checked={this.state.showAudited}
                                style={{ backgroundColor: this.state.showAudited ? "#20790A" : "#FFFFFF", borderColor: "#051A38", borderWidth: "1px", width: "20px", height: "20px" }}
                                className="legend-checkbox"
                                onChange={(e) => {
                                    const isChecked = e.syntheticEvent.target.checked;
                                    this.setState(
                                        { showAudited: isChecked, currentSelectedPole: null, },
                                        this.updateFilteredMapData
                                    );
                                }}
                            />

                        </div>
                    </GridLayoutItem>
                    <GridLayoutItem row={2} col={1} className="map-icon-layoutitem" >
                        <img src={selectedRedIcon} alt="Pole" />
                    </GridLayoutItem>
                    <GridLayoutItem className="pulse-map-legend-grid" row={2} col={2}>
                        <div className="legend-row" >
                            Not Audited
                           <Checkbox
                                checked={this.state.showNotAudited}
                                style={{ backgroundColor: this.state.showNotAudited ? "#FF0000" : "#FFFFFF", borderColor: "#051A38", borderWidth: "1px", width: "20px", height: "20px" }}
                                className="legend-checkbox"
                                onChange={(e) => {
                                    const isChecked = e.syntheticEvent.target.checked;
                                    this.setState(
                                        { showNotAudited: isChecked, currentSelectedPole: null, },
                                        this.updateFilteredMapData
                                    );
                                }}
                            />
                        </div>
                    </GridLayoutItem>
                </GridLayout>
            </div>
        );

        const legendWithFilterApplied = (
            <div className="pulse-map-legend-rs-nofilter">
                <GridLayout
                    gap={{
                        rows: 2,
                        cols: 2,
                    }}
                >
                    <GridLayoutItem row={1} col={1} className="map-icon-layoutitem">
                        <img src={selectedGreenIcon} alt="Audited Pole" />
                    </GridLayoutItem>
                    <GridLayoutItem className="pulse-map-legend-grid" row={1} col={2}>
                        <div className="legend-row">Audited</div>
                    </GridLayoutItem>
                    <GridLayoutItem row={2} col={1} className="map-icon-layoutitem">
                        <img src={selectedRedIcon} alt="Not Audited Pole" />
                    </GridLayoutItem>
                    <GridLayoutItem className="pulse-map-legend-grid" row={2} col={2}>
                        <div className="legend-row">Not Audited</div>
                    </GridLayoutItem>
                </GridLayout>
            </div>
        );

        const NoDataComponent = () => (
            <div>
                <img className='pulse-no-access-image' src={noaccess} alt="No Access" />
                <h3 className='pulse-no-access-image-text'>This project does not have any data yet.</h3>
            </div>
        );

        return (
            <>
                {this.state.nodata ? (
                    <NoDataComponent />
                ) : this.state.loading ? (
                    <div className='nimbly2-map-loader-rs'>
                        <img className='nimbly2-map-loader-gif-rs' src={loadinggif} alt="Loading..." />
                        <img className='nimbly2-map-loader-text-rs' src={loadingtext} alt="Loading Text" />
                    </div>
                ) : (
                            <div className={this.state.loading ? "nimbly-map-loading" : "map-container"} >
                                <GoogleMap
                                    mapContainerStyle={this.props.fullSizeMap ? containerStyleFullSize : containerStyle}
                                    center={this.state.currentCenter}
                                    zoom={this.state.zoomLevel}
                                    options={getMapOptions()}
                                    onFail={error => console.log(error)}
                                >
                                    <div className="map-button-group">
                                        <Button
                                            onClick={() => this.handleClearMapFilter()}
                                            className="map-button"
                                        >
                                            Reset Map Filter
                            </Button>
                                        {highlightedPoles && highlightedPoles.length > 0 && (
                                            <Button
                                                onClick={() => this.handleGoToReport()}
                                                className="map-button"
                                            >
                                                Go to Report
                                </Button>
                                        )}
                                    </div>
                                    {/*<input
                            onKeyUp={(e) => this.handleKeyPress(e)}
                            type="text"
                            placeholder="Search by Structure ID"
                            style={{
                                boxSizing: `border-box`,
                                border: `1px solid transparent`,
                                width: `300px`,
                                height: `32px`,
                                padding: `0 12px`,
                                borderRadius: `3px`,
                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                fontSize: `14px`,
                                outline: `none`,
                                textOverflow: `ellipses`,
                                position: "absolute",
                                left: "85%",
                                marginLeft: "-140px",
                                marginTop: "10px"
                            }}
                        />*/}
                                    {
                                        <Autocomplete
                                            onLoad={this.onLoad}
                                            onPlaceChanged={this.onPlaceChanged}
                                        //onFail={error => console.log(error)}
                                        >
                                            <input
                                                type="text"
                                                placeholder="Search by Address"
                                                style={{
                                                    boxSizing: 'border-box',
                                                    border: '1px solid transparent',
                                                    width: '300px',
                                                    height: '32px',
                                                    padding: '0 12px',
                                                    borderRadius: '3px',
                                                    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                                                    fontSize: '14px',
                                                    outline: 'none',
                                                    textOverflow: 'ellipsis',
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '10px',
                                                }}
                                            />
                                        </Autocomplete>
                                    }
                                    {
                                        this.state.filteredMapData == null || this.state.filteredMapData.length <= 0
                                            ? (
                                                "loading"
                                            )
                                            : (
                                                <>
                                                    {this.state.filteredMapData.map((item, index) => (
                                                        <MarkerF
                                                            key={index}
                                                            position={item.coords}
                                                            onClick={(e) => this.handleMarkerOnClick(item, e)}
                                                            icon={this.assignPoleSymbologyColor(item)}
                                                        >
                                                            {this.state.currentSelectedPole !== null &&
                                                                this.state.currentSelectedPole.info.Globslid === item.info.Globslid && (
                                                                    <InfoWindow key={index} position={item.coords}>
                                                                        <ExpandedPole pole={item} />
                                                                    </InfoWindow>
                                                                )}
                                                        </MarkerF>
                                                    ))}
                                                </>
                                            )
                                    }
                                    <></>
                                </GoogleMap>
                                {
                                    //<div className="pulse-map-view-button-container">
                                    //    <Button className="pulse-map-view-button" onClick={() => this.handleViewButtonOnClick()}>View in Report Wizard</Button>
                                    //</div>
                                }
                                {
                                    !this.state.loading && (
                                        highlightedPoles && highlightedPoles.length > 0
                                            ? legendWithFilterApplied
                                            : legendWithoutFilterApplied
                                    )}
                            </div>
                        )}
            </>
        );
    }

    async populateMapData(rawQuery) {
        const { selectedProject, GCP_Project, Dataset_ID } = this.props.projectContext;
        const { highlightedPoles } = this.props.highlightedPolesContext;

        this.setState({ loading: true });

        try {
            fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/Secrets/GetNimblyWebAPIKey', {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*" },
                // mode: 'no-cors',
            })
                .then((res) => {
                    return res.text();;
                })
                .then((data) => {
                    //fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/poles/GetRSPoles', {
                    fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/poles/GetRSPoles', {
                        method: 'POST',
                        //headers: { 'Content-Type': 'application/json', 'ApiKey': data, },
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            StartDateRemoval: "",
                            EndDateRemoval: "",
                            StartDateAudit: "",
                            EndDateAudit: "",
                            Pole_Number: "",
                            Pole_Status: "",
                            Pull_Type: "this is for the map, should not run1123221",
                            State: "",
                            Region: "",
                            Project: selectedProject,
                            GCP_Project: GCP_Project,
                            Dataset_ID: Dataset_ID
                        })
                    })
                        .then((res) => {
                            if (!res.ok) {
                                this.setState({ loading: false, nodata: true });
                                throw new Error('Failed to fetch data');
                            }
                            return res.json().catch(() => {
                                this.setState({ loading: false, nodata: true });
                                throw new Error('Invalid JSON response');
                            });
                        })

                        .then((data) => {
                            //console.log(data);
                            const markerList = [];
                            const poleadderList = [];
                            const poleconditionList = [];
                            for (var index in data) {
                                var pole = data[index];
                                var obj = {};
                                var poleInfo = {};
                                poleInfo["Audit_User"] = null;
                                poleInfo["Audit_Date"] = null;
                                let lat = 0;
                                let long = 0;
                                if (pole && pole.fieldDict && pole.fieldDict.Location_Details && pole.fieldDict.Location_Details.Coordinates && pole.fieldDict.Location_Details.Coordinates != "") {

                                    let arr = pole.fieldDict.Location_Details.Coordinates.split("-");
                                    if (arr.length == 2) {

                                        lat = Number(arr[0]);
                                        long = -(Number(arr[1]));
                                    }
                                }


                                //if (pole && pole.fieldDict && pole.fieldDict.Poles && pole.fieldDict.Poles["Structure ID"]) {
                                //    poleInfo["info"].Photos = pole.photosDict;
                                //}

                                poleInfo["info"] = pole && pole.fieldDict && pole.fieldDict.Poles ? pole.fieldDict.Poles : null;
                                poleInfo["Attachers"] = pole && pole.attachmentsDict && pole.attachmentsDict ? Object.values(pole.attachmentsDict) : [];

                                obj["lat"] = lat;
                                obj["lng"] = long;

                                poleInfo["allData"] = data[index];

                                poleInfo["coords"] = obj;


                                poleInfo.info = {

                                }
                                poleInfo["info"].Lat = lat.toString();
                                poleInfo["info"].Long = long.toString();
                                if (pole && pole.fieldDict && pole.fieldDict.Poles && pole.fieldDict.Poles["Audit User"]) {
                                    poleInfo["Audit_User"] = pole.fieldDict.Poles["Audit User"];
                                }
                                if (pole && pole.fieldDict && pole.fieldDict.Poles && pole.fieldDict.Poles["Audit Date"]) {
                                    poleInfo["Audit_Date"] = pole.fieldDict.Poles["Audit Date"];
                                }
                                if (pole && pole.fieldDict && pole.fieldDict.Poles && pole.fieldDict.Poles["Pole Status"]) {
                                    poleInfo["info"]["Pole_Status"] = pole.fieldDict.Poles["Pole Status"];
                                }
                                if (pole && pole.fieldDict && pole.fieldDict.Poles && pole.fieldDict.Poles["Alternate Structure ID"]) {
                                    poleInfo["info"].Pole_Number = pole.fieldDict.Poles["Structure ID"];
                                }
                                if (pole && pole.fieldDict && pole.fieldDict.Poles && pole.fieldDict.Poles.GlobalID) {
                                    poleInfo["info"].Globslid = pole.fieldDict.Poles.GlobalID;
                                }

                                poleInfo["highlighted"] = false;

                                markerList.push(poleInfo);
                            }

                            markerList.forEach((marker) => {
                                if (highlightedPoles.includes(marker.info ?.Globslid)) {
                                    marker.highlighted = true;
                                }
                            });

                            const result = markerList.filter((pole) => pole.coords.lat != 0);

                            this.setState({
                                currentCenter: result[0].coords
                            })

                            if (result.length === 0) {
                                this.setState({ loading: false, nodata: true });
                            } else if (rawQuery === "") {
                                this.setState({
                                    mapData: result,
                                    filteredMapData: result,
                                    loading: false,
                                    poleadderList: poleadderList,
                                    poleconditionList: poleconditionList,
                                    counts: result.length,
                                }, () => {
                                    this.updateFilteredMapData();
                                });
                            } else {
                                this.setState({
                                    mapData: markerList,
                                    filteredMapData: markerList,
                                    loading: false,
                                    counts: markerList.length,
                                });
                            }

                            this.updateFilteredMapData();
                        });
                });
        } catch (error) {
            console.error("Error fetching map data:", error);
            this.setState({ loading: false, nodata: true });
        }
    }
}
export default withProject(withHighlightedPoles(withTabContext(RSPoleMap)));
