import React, { createContext, useContext, useState } from 'react';

const HighlightedPolesContext = createContext();

export const HighlightedPolesProvider = ({ children }) => {
    const [highlightedPoles, setHighlightedPoles] = useState([]); 

    const hasHighlightedPoles = highlightedPoles.length > 0;

    return (
        <HighlightedPolesContext.Provider value={{ highlightedPoles, setHighlightedPoles, hasHighlightedPoles }}>
            {children}
        </HighlightedPolesContext.Provider>
    );
};

export const useHighlightedPoles = () => useContext(HighlightedPolesContext);
