import React from "react";
import "../styles/nimbly2.css";
import pole1 from "../assets/images/pole1.jpg";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

const ExpandedPole = ({ pole }) => {
    //console.log(pole);
    //console.log(Object.keys(pole.allData.fieldDict));
    //console.log("here");
    return (
        <div className="nimbly-pole-popup-wrapper">
            <header className="nimbly-pole-popup-header">
                Pole #{pole.info["Pole_Number"]}
            </header>
            <div className="nimbly-pole-popup-img-wrapper">
                {/* Image Containers */}
                {!(pole.allData && pole.allData.photosDict != null && Object.values(pole.allData.photosDict).length > 0) ? <div>
                    No Photos
                            </div> : Object.values(pole.allData.photosDict)?.map((photo, x) => (
                        <div className="nimbly-pole-img-container">
                            <a target="_blank" href={photo["Photo Path"]} rel="noreferrer"><img src={photo["Photo Path"]} alt="Pole" /></a>
                            {Object.keys(photo).filter(x => x != "Photo Path")?.map(photoField => (
                                <GridLayoutItem><strong>{photoField}</strong>:{photo[photoField]}</GridLayoutItem>
                                    )
                            )}
                        </div>
                    ))}
            </div>
            <div className="nimbly-pole-details-wrapper">
                {!(pole["allData"] && pole["allData"].fieldDict != null && Object.keys(pole.allData.fieldDict) != null) ? <div>
                    No Pole Data
                            </div>
                    : Object.keys(pole.allData.fieldDict) ?.map((tableName, tableIndex) => (
                     
                        <section className="nimbly-pole-details-section">
                            <GridLayout
                                gap={{
                                    rows: pole.allData.fieldDict[tableName] ? Object.keys(pole.allData.fieldDict[tableName]).length : 0,
                                    cols: 2,
                                }}
                                cols={[
                                    {
                                        width: "50%",
                                    },
                                    {
                                        width: "50%",
                                    }
                                ]}
                            >
                                <GridLayoutItem className="pulse-expand-title" row={1} col={1} colSpan={2}>
                                    {tableName}
                                </GridLayoutItem>
                                {Object.keys(pole.allData.fieldDict[tableName]) ?.map((fieldName, fieldIndex) => (
                                    <>
                                    <GridLayoutItem className="pulse-queryresult-expanded-detail-column" row={fieldIndex+2} col={1}>
                                        {fieldName}
                                    </GridLayoutItem>
                                    <GridLayoutItem row={fieldIndex+2} col={2}>
                                        {pole.allData.fieldDict[tableName][fieldName]}
                                        </GridLayoutItem>
                                        </>
                            ))}


                            </GridLayout>
                        </section>



                    ))}
                    {pole.Attachers == null ? null :
                    pole.Attachers ?.map((attacher, x) => (
                            <section className="nimbly-pole-details-section">
                            <GridLayout
                                gap={{
                                    rows: Object.keys(attacher) ? Object.keys(attacher).length : 0,
                                  cols: 2,
                                }}
                                cols={[
                                    {
                                        width: "50%",
                                    },
                                    {
                                        width: "50%",
                                    }
                                ]}
                            >

                                <GridLayoutItem className="pulse-expand-title" row={1} col={1} colSpan={2}>
                                    Attacher Information {x+1}
                                </GridLayoutItem>
                                {Object.keys(attacher) ?.map((key, index) => (
                                    <>
                                        <GridLayoutItem className="pulse-queryresult-expanded-detail-column" row={index+2} col={1}>
                                            {key}
                                        </GridLayoutItem>
                                        <GridLayoutItem row={index+2} col={2}>
                                            {attacher[key]}
                                        </GridLayoutItem>
                                    </>
                                ))}


                                </GridLayout>
                            </section>


                        ))}
            </div>
        </div>
    );
};

export default ExpandedPole;
