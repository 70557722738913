import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
    const { logout } = useAuth0();

    return (
        <a className="nimbly-logoutButton"  onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
            Sign out
        </a>
    );
};

export default LogoutButton;