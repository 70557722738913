import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { DateRangePicker as KenDateRangePicker } from "@progress/kendo-react-dateinputs";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { ButtonGroup as KenButtonGroup, Button as KenButton } from "@progress/kendo-react-buttons";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { Grid as KenGrid, GridColumn as KenColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { xIcon } from "@progress/kendo-svg-icons";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import loadinggif from "../../assets/loading.gif";
import { process } from "@progress/kendo-data-query";
import { useProject } from '../../contexts/ProjectContext';
import { useHighlightedPoles } from '../../contexts/HighlightedPolesContext';
import { useQueryResults } from '../../contexts/QueryResultsContext';
import { useTabContext } from '../../contexts/ReportTabContext';

const sports = [
    "Baseball",
    "Basketball",
    "Cricket",
    "Field Hockey",
    "Football",
    "Table Tennis",
    "Tennis",
    "Volleyball",
];

const products = [{
    "ProductID": 1,
    "ProductName": "Chai",
    "SupplierID": 1,
    "CategoryID": 1,
    "QuantityPerUnit": "10 boxes x 20 bags",
    "UnitPrice": 18.0000,
    "UnitsInStock": 39,
    "UnitsOnOrder": 0,
    "ReorderLevel": 10,
    "Discontinued": false,
    "Category": {
        "CategoryID": 1,
        "CategoryName": "Beverages",
        "Description": "Soft drinks, coffees, teas, beers, and ales"
    }
}, {
    "ProductID": 2,
    "ProductName": "Chang",
    "SupplierID": 1,
    "CategoryID": 1,
    "QuantityPerUnit": "24 - 12 oz bottles",
    "UnitPrice": 19.0000,
    "UnitsInStock": 17,
    "UnitsOnOrder": 40,
    "ReorderLevel": 25,
    "Discontinued": false,
    "Category": {
        "CategoryID": 1,
        "CategoryName": "Beverages",
        "Description": "Soft drinks, coffees, teas, beers, and ales"
    }
}];

const min = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

const CSReportPage = () => {
    const pdfExportComponent = React.useRef(null);

    const { queryResults, setQueryResults, isQueryActive } = useQueryResults();
    const { highlightedPoles, setHighlightedPoles } = useHighlightedPoles();
    const { selectedTab, setSelectedTab } = useTabContext();
    const excelExportRef = useRef(null);
    const [StartDateRemoval, setStartDateRemoval] = React.useState();
    const navigate = useNavigate();
    const [EndDateRemoval, setEndDateRemoval] = React.useState();
    const [StartDateAudit, setStartDateAudit] = React.useState();
    const [EndDateAudit, setEndDateAudit] = React.useState();
    const [PoleNumber, setPoleNumber] = React.useState();
    const [poleNumberList, setPoleNumberList] = React.useState();
    const [PoleStatus, setPoleStatus] = React.useState();
    const [PoleType, setPoleType] = React.useState();
    const [NJUNS, setNJUNS] = React.useState();
    const [SPANS, setSPANS] = React.useState();
    const [Region, setRegion] = React.useState();
    const [State, setState] = React.useState();
    const [showWarning, setshowWarning] = React.useState(false);
    const [numblerlist, setnumblerlist] = React.useState([]);
    const [statelist, setstatelist] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [filter, setFilter] = useState([]);
    const [typelist, settypelist] = React.useState([]);
    const [statuslist, setstatuslist] = React.useState([]);
    const [regionlist, setregionlist] = React.useState([]);
    const [pageSizeValue, setPageSizeValue] = React.useState();
    const { selectedProject, GCP_Project, Dataset_ID, previousProject, handleProjectChange } = useProject();
    const [dataState, setDataState] = React.useState({
        skip: 0,
        take: 10
    });
    const [allExpanded, setAllExpanded] = React.useState(false);

    useEffect(() => {
        if (selectedProject) {
            populateDropDownData();
            resetQueryFormAndSwitchTab();
            setPoleNumberList([]);
            setDataResult(process([], dataState));
        }
        setDataResult({
            ...dataResult,
            data: [...dataResult.data],
        });
    }, [selectedProject]);

    useEffect(() => {
        const allRowsExpanded = queryResults.length > 0 && queryResults.every(item => item.expanded);
        setAllExpanded(allRowsExpanded);
    }, [queryResults]);

    const expandChange = (event) => {
        const isExpanded =
            event.dataItem.expanded === undefined
                ? event.dataItem.aggregates
                : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;
        setDataResult({
            ...dataResult,
            data: [...dataResult.data],
        });
    };

    const expandAllRows = (shouldExpand) => {
        let updatedExpandStatus = !allExpanded;
        let newData = queryResults.map((item) => {
            item.expanded = updatedExpandStatus
            return item;
        });
        setDataResult({
            ...dataResult,
            data: [...dataResult.data],
        });
        setAllExpanded(!allExpanded);
    };

    const viewInMap = () => {
        const highlightedPolesList = queryResults.map(item => item.GlobalId);
        setHighlightedPoles(highlightedPolesList);
        const updatedPoleData = queryResults.map(item => ({
            ...item,
            highlighted: highlightedPolesList.includes(item.GlobalId)
        }));

        navigate('/map');
    };


    const [dataResult, setDataResult] = React.useState(
        process(queryResults, dataState)
    );
    const dataStateChange = (event) => {
        setDataResult(process(queryResults, event.dataState));
        setDataState(event.dataState);
    };

    const gridWidth = 600;

    const onChange = (event) => {

        setPoleNumber([...event.value]);
    };
    const handleSelect = (e) => {
        //console.log(e.selected);
        setSelectedTab(e.selected);
    };

    const handleSubmit = () => {
        if ((StartDateRemoval == "" || StartDateRemoval == null) &&
            (EndDateRemoval == "" || EndDateRemoval == null) &&
            (StartDateAudit == "" || StartDateAudit == null) &&
            (EndDateAudit == "" || EndDateAudit == null) &&
            (formatPoleNumberList(poleNumberList) == "" || poleNumberList == null) &&
            (PoleStatus == "" || PoleStatus == null) &&
            (PoleType == "" || PoleType == null) &&
            (NJUNS == "" || NJUNS == null) &&
            (SPANS == "" || SPANS == null) &&
            (Region == "" || Region == null) &&
            (State == "" || State == null)) {
            setshowWarning(true);
        } else {
            setshowWarning(false);
            onWizardSubmit();

        }
    }

    const flattenObject = (obj, parentKey = '', result = {}) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                const newKey = parentKey ? `${parentKey}.${key}` : key;
                if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                    flattenObject(obj[key], newKey, result);
                } else {
                    result[newKey] = obj[key];
                }
            }
        }
        return result;
    };

    const exportRows = () => {
        if (excelExportRef.current && queryResults.length > 0) {
            excelExportRef.current.save();
        } else {
            alert("No data available to export.");
        }
    };


    const exportData = useMemo(() => {
        let dataArray = [];
        queryResults.forEach((item, index) => {
            // Flatten the Poles data to access fields easily
            const mainRowData = flattenObject(item.fieldDict ?.Poles || {});

            // Main Row with 'Row Type', 'Pole Number', 'Removal Date', 'Audit Date'
            const mainRow = {
                'Row Type': `Pole Result ${index + 1}`,
                'Pole Number': mainRowData['Pole Number'] || '',
                'Removal Date': mainRowData['Removal Date'] || '',
                'Audit Date': mainRowData['Audit Date'] || '',
                // All other fields are left empty
            };
            dataArray.push(mainRow);

            // Attacher Information Rows
            if (item.attachmentsDict) {
                Object.values(item.attachmentsDict).forEach((attacher, attacherIndex) => {
                    const attacherData = flattenObject(attacher);
                    const attacherRow = {
                        'Row Type': `Attacher Information ${attacherIndex + 1}`,
                        ...attacherData,
                    };
                    dataArray.push(attacherRow);
                });
            }

            // Location Details Row
            if (item.fieldDict ?.Location_Details) {
                const locationData = flattenObject(item.fieldDict.Location_Details);
                const locationRow = {
                    'Row Type': 'Location Details',
                    ...locationData,
                };
                dataArray.push(locationRow);
            }

            // Pole Information Row
            if (item.fieldDict ?.Poles) {
                const poleInfoData = flattenObject(item.fieldDict.Poles);
                const poleInfoRow = {
                    'Row Type': 'Pole Information',
                    ...poleInfoData,
                };
                dataArray.push(poleInfoRow);
            }
        });
        return dataArray;
    }, [queryResults]);

    const columns = useMemo(() => {
        // Predefined columns in the desired order
        const predefinedColumns = ['Row Type', 'Pole Number', 'Removal Date', 'Audit Date'];

        // Collect all keys from exportData
        const allColumnKeys = new Set();
        exportData.forEach(item => {
            Object.keys(item).forEach(key => {
                allColumnKeys.add(key);
            });
        });

        // Filter out predefined columns to get additional columns
        const additionalColumns = Array.from(allColumnKeys).filter(key => !predefinedColumns.includes(key));

        // Combine predefined columns and additional columns
        const allColumns = predefinedColumns.concat(additionalColumns);

        // Map to column definitions
        return allColumns.map(key => ({
            field: key,
            title: key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()),
        }));
    }, [exportData]);


    const populateDropDownData = () => {
    //console.log("populateChartData");
    setLoading(true);
    fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/Secrets/GetNimblyWebAPIKey', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*" },
        // mode: 'no-cors',
    })
    .then((res) => {
        return res.text();
    })
    .then((data) => {
        //console.log(data);
        fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/poles/GetPulseChart', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'ApiKey': data, },
            //headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({
                rawQuery: "",
                Project: selectedProject,
                GCP_Project: GCP_Project,
                Dataset_ID: Dataset_ID
            })
        })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            //console.log(data);
            setnumblerlist(data.PoleNumberList);
            //console.log(data);
            setstatelist(data.StateList);
            settypelist(data.PoleTypeList);
            setstatuslist(data.PoleStatusList);
            setregionlist(data.RegionList);
            setLoading(false);
        });
    });
};

    const onWizardSubmit = () => {
        fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/Secrets/GetNimblyWebAPIKey', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then((res) => res.text())
            .then((apiKey) => {
                setLoading(true);
                fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/poles/TestGetPulsePoles', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'ApiKey': apiKey
                    },
                    body: JSON.stringify({
                        StartDateRemoval: StartDateRemoval || "",
                        EndDateRemoval: EndDateRemoval || "",
                        StartDateAudit: StartDateAudit || "",
                        EndDateAudit: EndDateAudit || "",
                        Pole_Number: formatPoleNumberList(poleNumberList) || "",
                        Pole_Status: PoleStatus || "",
                        Pull_Type: PoleType ? PoleType.replace(/"/g, '\\"') : "",
                        State: State || "",
                        Region: Region || "",
                        Project: selectedProject,
                        GCP_Project: GCP_Project,
                        Dataset_ID: Dataset_ID
                    })
                })
                    .then((res) => res.json())
                    .then((data) => {
                        const flattenedData = data.map(item => ({
                            GlobalId: item.GlobalId,
                            fieldDict: item.fieldDict,
                            attachmentsDict: item.attachmentsDict,
                            photosDict: item.photosDict,
                            highlighted: false
                            }));

                        setSelectedTab(1); 
                        setQueryResults(flattenedData);
                        setHighlightedPoles([]);
                        setLoading(false);
                        setStartDateRemoval(null);
                        setEndDateRemoval(null);
                        setStartDateAudit(null);
                        setEndDateAudit(null);
                        setPoleNumber(null);
                        setPoleStatus(null);
                        setPoleType(null);
                        setNJUNS(null);
                        setSPANS(null);
                        setRegion(null);
                        setState(null);
                        setPoleNumberList([]);
                        setDataState({
                            skip: 0,
                            take: 10
                        });
                        setAllExpanded(false)
                    });
            });
    };


    useEffect(() => {
        setDataResult(process(queryResults, dataState));
    }, [queryResults]);

    const setPercentage = (percentage) => {
        return Math.round(gridWidth / 100) * percentage;
    };
    const handleInputChange = (event, type) => {
        //console.log(event.value, type);
        if (type === "StartDateRemoval") {
            setStartDateRemoval(event.value);
        } else if (type === "EndDateRemoval") {
            setEndDateRemoval(event.value);
        } else if (type === "StartDateAudit") {
            setStartDateAudit(event.value);
        } else if (type === "EndDateAudit") {
            setEndDateAudit(event.value);
        } else if (type === "PoleNumber") {
            setPoleNumber(event.value);
        } else if (type === "PoleStatus") {
            setPoleStatus(event.value);
        } else if (type === "PoleType") {
            setPoleType(event.value);
        } else if (type === "NJUNS") {
            setNJUNS(event.value);
        } else if (type === "SPANS") {
            setSPANS(event.value);
        } else if (type === "Region") {
            setRegion(event.value);
        } else if (type === "State") {
            setState(event.value);
        }
    };

    const handlePoleNumberChange = (e) => {
        const poleNumber = e.target.value;
        const isSelected = numblerlist.includes(poleNumber);
        const inPoleNumberList = poleNumberList != null ? poleNumberList.includes(poleNumber) : false;
        if (isSelected && !inPoleNumberList) {
            if (poleNumberList != null) {
                setPoleNumber(e.target.value);
                setPoleNumberList(oldArray => [poleNumber, ...oldArray]);
            } else {
                let temp = [poleNumber];
                setPoleNumber(e.target.value);
                setPoleNumberList(temp);
            }
        } else {
            setPoleNumber(e.target.value);
        }
    };

    const formatPoleNumberList = (li) => {
        let ret = "";
        if (li != null) {
            li.forEach(el => {
                ret += "\"" + el + "\"" + ",";
            });
        }
        return ret.substr(0, ret.length - 1);
    }

    const generateColumns = () => {
        if (queryResults.length === 0) return [];
        const sampleData = queryResults[0];
        return Object.keys(sampleData).map(key => (
            <Column key={key} field={key} title={key.replace(/_/g, ' ')} />
        ));
    };

const resetQueryFormAndSwitchTab = () => {
    setStartDateRemoval(null);
    setEndDateRemoval(null);
    setStartDateAudit(null);
    setEndDateAudit(null);
    setPoleNumber(null);
    setPoleStatus(null);
    setPoleType(null);
    setNJUNS(null);
    setSPANS(null);
    setRegion(null);
    setState(null);
};

    const SelectedPoleNumbers = ({ props }) => {
        if (props != null) {
            return (
                <div className="pulse-multi-pole-wrapper">
                    {props.map((num) => (
                        <div className="pulse-multi-pole">
                            <div className="pulse-multi-pole-label">{num}</div>
                            <Button
                                svgIcon={xIcon}
                                onClick={() => setPoleNumberList(poleNumberList.filter(a => a != num))}
                                className="pulse-multi-pole-x"
                                fillMode="link"
                            />
                        </div>
                    ))}
                </div>
            )
        } else {
            return <div/>
        }
    };

    const DetailComponent = (props) => {
        const dataItem = props.dataItem;
        //console.log(dataItem);
        const c = [1, 2, 3, 4];
        let last = 3;
        let first = 0;
        const picCol = dataItem.photosDict && Object.keys(dataItem.photosDict).length > 0 ? c[first++] : c[last--];
        const attacherCol = dataItem.attachmentsDict && Object.keys(dataItem.attachmentsDict).length > 0 ? c[first++] : c[last--];
        const locationCol = c[first++];
        const infoCol = c[first++];
        const renderFieldItems = (fields) => {
            return Object.entries(fields).map(([key, value], index) => (
                <React.Fragment key={index}>
                    <GridLayoutItem className="pulse-queryresult-expanded-detail-column" row={index + 2} col={1}>
                        {key.replace(/_/g, ' ')}:
                </GridLayoutItem>
                    <GridLayoutItem row={index + 2} col={2}>
                        {value}
                    </GridLayoutItem>
                </React.Fragment>
            ));
        };

        return (
            <GridLayout
                gap={{
                    rows: 2,
                    cols: 4,
                }}
                rows={[
                    {
                        height: 250,
                    }
                ]}
                cols={[
                    {
                        width: "25%",
                    },
                    {
                        width: "25%",
                    },
                    {
                        width: "25%",
                    },
                    {
                        width: "25%",
                    }
                ]}
            >
                {dataItem.photosDict && Object.keys(dataItem.photosDict).length > 0 ? (
                    <GridLayoutItem className="pulse-img-container" row={1} col={picCol}>
                        {dataItem.photosDict && Object.values(dataItem.photosDict).map((photo, x) => (
                            <a href={photo['Photo Path']} target="_blank" rel="noopener noreferrer" key={x}>
                                <img className="pulse-img" src={photo['Photo Path']} alt={`Photo ${x + 1}`} />
                            </a>
                        ))}
                    </GridLayoutItem>
                ) : null}

                {dataItem.attachmentsDict && Object.keys(dataItem.attachmentsDict).length > 0 ? (
                    <GridLayoutItem className="pulse-expand-content pulse-img-container" row={1} col={attacherCol}>
                        {Object.entries(dataItem.attachmentsDict).map(([attacherKey, attacher], x) => (
                            <GridLayout
                                gap={{
                                    rows: 8,
                                    cols: 2,
                                }}
                                cols={[
                                    {
                                        width: "49%",
                                    },
                                    {
                                        width: "49%",
                                    }
                                ]}
                                key={x}
                            >
                                <GridLayoutItem className="pulse-expand-title" row={1} col={1} colSpan={2}>
                                    Attacher Information {x + 1}
                                </GridLayoutItem>
                                {renderFieldItems(attacher)}
                            </GridLayout>
                        ))}
                    </GridLayoutItem>
                ) : (
                        null
                    )}

                <GridLayoutItem className="pulse-expand-content pulse-img-container" row={1} col={locationCol}>
                    <GridLayout
                        gap={{
                            rows: 8,
                            cols: 2,
                        }}
                        cols={[
                            {
                                width: "49%",
                            },
                            {
                                width: "49%",
                            }
                        ]}
                    >
                        <GridLayoutItem className="pulse-expand-title" row={1} col={1} colSpan={2}>
                            Location Details
                    </GridLayoutItem>
                        {renderFieldItems(dataItem.fieldDict.Location_Details)}
                    </GridLayout>
                </GridLayoutItem>

                <GridLayoutItem className="pulse-expand-content pulse-img-container" row={1} col={infoCol}>
                    <GridLayout
                        gap={{
                            rows: 14,
                            cols: 2,
                        }}
                        cols={[
                            {
                                width: "49%",
                            },
                            {
                                width: "49%",
                            }
                        ]}
                    >
                        <GridLayoutItem className="pulse-expand-title" row={1} col={1} colSpan={2}>
                            Pole Information
                    </GridLayoutItem>
                        {renderFieldItems(dataItem.fieldDict.Poles)}
                    </GridLayout>
                </GridLayoutItem>
            </GridLayout>
        );
    };

    return (
        <div className="ken-nimbly-page-content-wrapper">
            {loading ?
                <div className="loading-icon-container"><img className='nimbly2-loader-gif' src={loadinggif} /> </div>
                : null}
            <TabStrip selected={selectedTab} onSelect={handleSelect}>
                <TabStripTab title="QUERY WIZARD">
                    <p className="ken-nimbly-ov-main-title">Query Wizard</p>
                    <div className="pulse-wizard-container">
                        <div className="pulse-wizard-container-header">Generate Query</div>
                        <div className={!showWarning ? "pulse-warning-no-display" : "pulse-wizard-container-warning"}>Please fill in at least one valid value to execute query.</div>

                        <div className="pulse-wizard-container-2">
                            <GridLayout
                                gap={{
                                    rows: 4,
                                    cols: 4,
                                }}
                            >
                                <GridLayoutItem row={1} col={1} colSpan={4}>
                                    <p className="Pulse-report-form-title">REMOVAL DATE</p>
                                    <div className="box-divider"></div>
                                </GridLayoutItem>
                                <GridLayoutItem className="pulse-querywizard-column-text" row={2} col={1}>
                                    Start Date:
                                </GridLayoutItem>
                                <GridLayoutItem row={2} col={2}>
                                    <DatePicker min={min} placeholder="Choose a date..." onChange={(e) => handleInputChange(e, "StartDateRemoval")} />
                                </GridLayoutItem>
                                <GridLayoutItem className="pulse-querywizard-column-text" row={2} col={3}>
                                    End Date:
                                </GridLayoutItem>
                                <GridLayoutItem row={2} col={4}>
                                    <DatePicker min={min} placeholder="Choose a date..." onChange={(e) => handleInputChange(e, "EndDateRemoval")} />
                                </GridLayoutItem>
                                <GridLayoutItem row={3} col={1} colSpan={4}>
                                    <p className="Pulse-report-form-title">AUDIT DATE</p>
                                    <div className="box-divider"></div>
                                </GridLayoutItem>
                                <GridLayoutItem className="pulse-querywizard-column-text" row={4} col={1}>
                                    Start Date:
                                </GridLayoutItem>
                                <GridLayoutItem row={4} col={2}>
                                    <DatePicker min={min} placeholder="Choose a date..." onChange={(e) => handleInputChange(e, "StartDateAudit")} />
                                </GridLayoutItem>
                                <GridLayoutItem className="pulse-querywizard-column-text" row={4} col={3}>
                                    End Date:
                                </GridLayoutItem>
                                <GridLayoutItem row={4} col={4}>
                                    <DatePicker min={min} placeholder="Choose a date..." onChange={(e) => handleInputChange(e, "EndDateAudit")} />
                                </GridLayoutItem>

                            </GridLayout>
                            <GridLayout
                                className=""
                                gap={{
                                    rows: 8,
                                    cols: 2,
                                }}
                                cols={[
                                    { width: 150 }
                                ]}
                            >

                                <GridLayoutItem row={1} col={1} colSpan={7}>
                                    <p className="Pulse-report-form-title">          POLE INFO</p>
                                    <div className="box-divider"></div>
                                </GridLayoutItem>
                                <GridLayoutItem className="pulse-querywizard-column-text" row={2} col={1}>
                                    Pole Number:
                                </GridLayoutItem>
                                <GridLayoutItem row={2} col={2} colSpan={6}>
                                    <AutoComplete
                                        data={numblerlist}
                                        defaultValue=""
                                        value={PoleNumber}
                                        onChange={(e) => handlePoleNumberChange(e)}
                                    />
                                    <SelectedPoleNumbers props={poleNumberList}/>
                                </GridLayoutItem>
                                <GridLayoutItem className="pulse-querywizard-column-text" row={3} col={1} >
                                    Pole Status:
                                </GridLayoutItem>
                                <GridLayoutItem row={3} col={2} colSpan={6}>
                                    <DropDownList
                                        data={statuslist}
                                        value={PoleStatus}
                                        onChange={(e) => setPoleStatus(e.target.value)}
                                    />
                                </GridLayoutItem>
                                <GridLayoutItem className="pulse-querywizard-column-text" row={4} col={1} >
                                    Pole Type:
                                </GridLayoutItem>
                                <GridLayoutItem row={4} col={2} colSpan={6}>
                                    <DropDownList
                                        data={typelist}
                                        value={PoleType}
                                        onChange={(e) => setPoleType(e.target.value)}
                                    />
                                </GridLayoutItem>
                                <GridLayoutItem className="pulse-querywizard-column-text" row={5} col={1} >
                                    NJUNS Number:
                                </GridLayoutItem>
                                <GridLayoutItem row={5} col={2} colSpan={6}>
                                    <Input onChange={(e) => handleInputChange(e, "NJUNS")}/>
                                </GridLayoutItem>
                                <GridLayoutItem className="pulse-querywizard-column-text" row={6} col={1}>
                                    SPANS Number:
                                </GridLayoutItem>
                                <GridLayoutItem row={6} col={2} colSpan={6}>
                                    <Input onChange={(e) => handleInputChange(e, "SPANS")} />
                                </GridLayoutItem>
                                <GridLayoutItem className="pulse-querywizard-column-text" row={7} col={1} >
                                    Region:
                                </GridLayoutItem>
                                <GridLayoutItem row={7} col={2} colSpan={6}>
                                    <DropDownList
                                        data={regionlist}
                                        value={Region}
                                        onChange={(e) => handleInputChange(e, "Region")}
                                    />

                                </GridLayoutItem>
                                <GridLayoutItem className="pulse-querywizard-column-text" row={8} col={1}>
                                    State:
                                </GridLayoutItem>
                                <GridLayoutItem row={8} col={2} colSpan={6}>
                                    <DropDownList
                                        data={statelist}
                                        value={State}
                                        onChange={(e) => setState(e.target.value)}
                                    />
                                </GridLayoutItem>
                                <GridLayoutItem className="pulse-textalignment-center" row={9} col={2} colSpan={2}>
                                    <Button
                                        themeColor={"primary"}
                                        type={"submit"}
                                        className="pulse-wizard-submit-button"
                                        onClick={() => handleSubmit()}
                                    >
                                        Execute Query
                                    </Button>
                                </GridLayoutItem>
                            </GridLayout>
                        </div>
                    </div>
                </TabStripTab>
                <TabStripTab title="QUERY REPORT">
                    <div className="pulse-query-wizard-top-row">
                        <p className="ken-nimbly-ov-main-title">Query Report</p>
                        <div className="report-action-btn-group">
                            {/*<Button onClick={exportRows} disabled={queryResults.length === 0} className="pulse-expand-collapse-report-button">
                                <span>Export</span>
                            </Button> THIS IS COMMENTED OUT FOR THE TIME BEING DUE TO JOHNS REQUEST */}
                            <Button onClick={() => viewInMap()} disabled={!isQueryActive} className="pulse-expand-collapse-report-button">
                                <span>View Results in Map</span>
                            </Button>
                            <Button onClick={() => expandAllRows()} disabled={queryResults.length === 0} className="pulse-expand-collapse-report-button">
                                <span>{allExpanded ? "Collapse" : "Expand"} All</span>
                            </Button>
                        </div>
                        </div>
                    <div>
                        <div className="pulse-wizard-container-header">Query Results</div>
                        <ExcelExport
                            ref={excelExportRef}
                            data={exportData}
                            columns={columns}
                            fileName={`QueryResults_${new Date().toISOString()}.xlsx`}
                        />
                        <Grid
                                data={dataResult}
                                {...dataState}
                                onDataStateChange={dataStateChange}
                                detail={DetailComponent}
                                style={{
                                    height: "100%",
                                }}
                                expandField="expanded"
                                onExpandChange={expandChange}
                                filterable={true}
                                pageable={{
                                    buttonCount: 4,
                                    pageSizes: [5, 10, 15, 20],
                                }}
                            >
                            <Column field="fieldDict.Poles.Pole Number" title="Pole Number" width={setPercentage(55)} />
                            <Column field="fieldDict.Poles.Removal Date" title="Removal Date" width={setPercentage(55)} />
                            <Column field="fieldDict.Poles.Audit Date" title="Audit Date" width={setPercentage(55)} />
                            </Grid>
                    </div>

                </TabStripTab>

            </TabStrip>





        </div>

    );
};

export default CSReportPage;
